<template>
  <div
      class="tw-h-full tw-flex tw-flex-col tw-bg-gray-n0 tw-border-1 tw-border-gray-n1 tw-rounded-lg tw-p-4"
  >

<!--    id and date-->
    <span class="tw-flex tw-justify-between tw-text-xs tw-pb-4">

      <span class="tw-font-semibold">
        #{{order.id}}
      </span>

      <span class="tw-font-normal tw-text-gray-n3">
        {{order.created_at}}
      </span>

    </span>

<!--    avatar and vendor name-->
    <span class="tw-block tw-font-semibold tw-text-base tw-pb-4">
      <v-avatar size="40px" class="tw-mr-3">
        <v-img :src="order.vendor.avatar"></v-img>
      </v-avatar>
      {{order.vendor.name}}
    </span>

<!--    price and date-->
    <span class="tw-text-sm tw-text-gray-n3 tw-pb-5">
      Price: {{order.total_price_with_tax | currency}}
      <div
          v-for="(i,index) in order.booked_dates" :key="index"
          class="tw-mt-2"
      >
        Date: {{dateFormat(i.datef)}}
      </div>

    </span>

<!--    labels-->
    <span class="tw-flex tw-flex-wrap tw-gap-2 tw-pb-5">

<!--      order status-->
      <OrderChangeLabel :label="order.status.name + ' Order'"/>

<!--      payment status-->

      <div
          v-if="order.transaction_amount == 0"
          class="tw-px-3 tw-py-2 tw-rounded-full tw-bg-gray-n1 tw-text-black tw-h-8 tw-no-underline tw-items-center tw-leading-4 tw-font-semibold tw-flex tw-justify-center"
      >
        <span>Unpaid</span>
      </div>
      <div
          v-else-if="order.transaction_amount != 0 && !order.pfc"
          class="tw-px-3 tw-py-2 tw-rounded-full tw-bg-antiquewhite tw-text-antiquewhite-100 tw-h-8 tw-no-underline tw-items-center tw-leading-4 tw-font-semibold tw-flex tw-justify-center"
      >
        <span>Partially paid</span>
      </div>
      <div
          v-else-if="order.pfc"
          class="tw-px-3 tw-py-2 tw-rounded-full tw-bg-green-100 tw-text-green tw-h-8 tw-no-underline tw-items-center tw-leading-4 tw-font-semibold tw-flex tw-justify-center"
      >
        <span>Fully paid</span>
      </div>
      <div
          v-else
          class="tw-px-3 tw-py-2 tw-rounded-full tw-bg-gray-n2 tw-text-black tw-h-8 tw-no-underline tw-items-center tw-leading-4 tw-font-semibold tw-flex tw-justify-center"
      >
        <span>Pending</span>
      </div>


<!--      order change label-->
      <div v-if="order.change">
        <OrderChangeLabel :label="order.change.status + ' Order Change'" :isClickable="labelClickable(order.change.status)" @click="onOrderChange(order)"/>
      </div>
      <div
          v-else-if="!order.pfc && !order.change && !order.rejected && order.is_approved"
          class="tw-border tw-inline-flex tw-font-semibold
                     tw-leading-relaxed tw-px-8 tw-py-1 tw-text-black
                     tw-border-gray-n1 tw-rounded-full tw-no-underline tw-cursor-pointer tw-bg-white"
          @click="onAdjustBooking(order)"
      >
        <span>Adjust a Booking</span>
      </div>



    </span>


    <div class="tw-mt-auto tw-flex tw-flex-wrap tw-justify-between tw-gap-2 ">
      <Button
          type="s_dark"
          @click="onViewDetails(order)"
      >
        <EyeIcon/>
        View Details
      </Button>

      <Button
          type="s_dark"
          newClass="tw-px-4"
          @click="onContract(order)"
      >
        <FileIcon/>
      </Button>

    </div>




  </div>
</template>

<script>
import OrderChangeLabel from '@/components/OrderChangeLabel';
import Button from '@/components/Button'
import { EyeIcon, FileIcon } from 'vue-feather-icons';
export default {
  props: [
      'order',
      'onViewDetails',
      'onContract',
      'onOrderChange',
      'onAdjustBooking'
  ],
  components: {
    OrderChangeLabel,
    Button,
    EyeIcon,
    FileIcon,
  },
  methods: {
    getColorClass(status) {
      if (status === 'Open') return 'yellow--text'
      else if (status === 'Approved') return 'black--text'
      else if (status === 'Pending') return 'orange--text'
      else if (status === 'Processed') return 'blue--text'
      else if (status === 'Delivered') return 'green--text'
      else return 'red--text'
    },
    labelClickable(label){
      if(label === "Accepted" || label === "Confirmed")
        return true;
      return false;
    },
  },
}
</script>
<style scoped>
.min-height{
  min-height:370px;
}

</style>